export class Formatter {

  static numberToCurrency(num: number | string): string {
    return Number(num).toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' });
  }

  static currencyToNumer(currency: string): number {
    if (!currency) {
      return 0;
    }

    return Number(currency.replace(/R/, '').replace(/,/g, '').trim());
  }

  static stringAsTitleCase(text: string): string {
    return text.replace(/(\w)(\w*)/g, (_g0: any, g1: string, g2: string) => g1.toUpperCase() + g2.toLowerCase());
  }
}
