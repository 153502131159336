import React from 'react';
import styled from 'styled-components';

const PageBanner = ({ className = '', title, subtitle }) => (
  <section className={className}>
    <h1>
      {title}
    </h1>
    <span>{subtitle}</span>
  </section>
);

export default styled(PageBanner)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  background-color: rgb(103 67 242);
  color: white;
  width: 100%;
  height: 40vh;

  h1 {
    font-size: 70px;
    line-height: 1.2em;
    width: 60vw;
    margin-bottom: 40px;
  }
  span {
    width: 60vw;
    font-size: 14px;
    line-height: 1.6em;
  }

  @media only screen and (max-width: 768px) {
    height: calc(100vh - var(--header-height));

    h1 {
      font-size: 35px;
    }
  }
`;
