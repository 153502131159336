import React, { useState, useCallback, useEffect } from 'react';
import { Accordion, Input, List, Message, Placeholder, Table } from 'semantic-ui-react';
import { useDebounce } from 'use-debounce';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import { Formatter } from '../utils/Formatter';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Http } from '../services/Http';
import { on } from '../utils/Async';
import PageBanner from '../components/PageBanner';

const padNumber = (value: number) => new String(value).padStart(2, '0');
const costStructure = [
  {
    tier: 'Tier 1',
    amountBracket: 'less than R 3 500',
    cost: 'base fee only',
  },
  {
    tier: 'Tier 2',
    amountBracket: 'more than R 3 500 but less than R 100 000',
    cost: 'base fee + 1% of amount',
  },
  {
    tier: 'Tier 3',
    amountBracket: 'more than R 100 000 but less than R 500 000',
    cost: 'base fee + 0.75% of amount',
  },
  {
    tier: 'Tier 4',
    amountBracket: 'more than R 500 000 but less than R 750 000',
    cost: 'base fee + 0.5% of amount',
  },
  {
    tier: 'Tier 5',
    amountBracket: 'more than R 750 000',
    cost: 'base fee + 0.25% of amount',
  },
];

const INPUT_DEBOUNCE = 250;
const CostBreakdown = () => {
  const [state, setState] = useState<'idle' | 'pending' | 'failure'>();
  const [amount, setAmount] = useState<string>('100');
  const [debouncedAmount] = useDebounce(parseInt(amount, 10), INPUT_DEBOUNCE);
  const [[cost, totalAmount, costBreakdown], setCostDetails] = useState<[number, number, string]>([0, 0, '']);

  useEffect(() => {
    if (!debouncedAmount) {
      setState('idle');
      return;
    }

    setState('pending');

    (async function calculateCost() {
      if (!debouncedAmount) {
        return setCostDetails([0, 0, '']);
      }

      const [responseError, response] = await on(Http.calculateTransactionCost(debouncedAmount));
      if (responseError) {
        setState('failure');
        return setCostDetails([0, 0, '']);
      }

      setState('idle')
      return setCostDetails([response!.cost!, debouncedAmount + response!.cost!, response!.detail!]);
    })();
  }, [debouncedAmount]);

  return (
    <div className="cost-breakdown">
      <h5>See some live numbers for yourself.</h5>
      <Table celled>
        <Table.Header>
          <Table.Row className="uncelled">
            <Table.HeaderCell>Cost Calculator</Table.HeaderCell>
            <Table.HeaderCell>
              <Input
                fluid
                name="number"
                placeholder="Enter your amount"
                type="number"
                value={amount}
                onChange={(_event, { value }) => setAmount(value)}
                min="0"
                required
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width="8">Total Cost</Table.HeaderCell>
            <Table.HeaderCell width="8">Cost Breakdown</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>
              {state === 'pending' ? (
                <Placeholder fluid>
                  <Placeholder.Paragraph>
                    <Placeholder.Line length='very short' />
                  </Placeholder.Paragraph>
                </Placeholder>
              ) : (debouncedAmount > 0 ? Formatter.numberToCurrency(cost) : null)}
            </Table.Cell>
            <Table.Cell>
              {state === 'pending' ? (
                <Placeholder fluid>
                  <Placeholder.Paragraph>
                    <Placeholder.Line length='very short' />
                  </Placeholder.Paragraph>
                </Placeholder>
              ) : (debouncedAmount > 0 ? costBreakdown : null)}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <h5>How is the cost actually calculated?</h5>
      <Message info>
        <p>The base fee for all transactions is R 4.99</p>
      </Message>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Tier</Table.HeaderCell>
            <Table.HeaderCell>Transaction Amount</Table.HeaderCell>
            <Table.HeaderCell>Cost</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {costStructure.map(({ tier, amountBracket, cost }) => (
            <Table.Row key={tier}>
              <Table.Cell>
                {tier}
              </Table.Cell>
              <Table.Cell>{amountBracket}</Table.Cell>
              <Table.Cell>{cost}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

const rows = [{
  id: 'cost',
  title: 'How much will it cost me?',
  body: <CostBreakdown />
}, {
  id: 'dispute',
  title: 'What if I would like to reverse a payment?',
  body: (
    <div className="dispute-breakdown">
      <h3>1. The short version</h3>
      <Message info>
        At foongit we understand that sometimes as people we may not agree on the first few times so we have allowed a
        maximum of 3 opportunities to freeze a transaction.
      </Message>
      <h5>What does foongit's freeze mean?</h5>
      <p>
        Well that would be when you are not happy with anything that pertains to the transaction or feel as though you
        are about to get scammed, you would then freeze to have the funds that you paid into the foongit vault reversed
        back into your bank account. As a service provider/supplier of goods you can also freeze a transaction if you are
        unhappy and or feel that you are being scammed.
        {/* (TK to insert step by step screen grabs of how to freeze a transaction) */}
        <br />
        Both parties will get 3 opportunities to respond to a frozen transaction, on the third time the response is that of
        a disagreement, then the transaction will go into a dispute state.
      </p>
      <h5>What does foongit's dispute mean? </h5>
      <p>
        Well it means you and the other party could not come to an agreement (common ground/compromise) and now the funds
        are locked in the foongit vault, no one is running away with the money, at least you do not have to stress about that.
      </p>
      <p>
        <Message warning>
          Please that note as soon as the transaction is in dispute the following needs to take place before the funds can
          be released to whom they are due after the outcome of an arbitration/trial/law suit.
        </Message>
        Kind note the various consumer complaint bodies available to either party before funds may be released.
        <List bulleted>
          <List.Item>SA Consumer Complaints</List.Item>
          <List.Item>Consumer Goods and Services Ombud ("CGSO")</List.Item>
          <List.Item>National Consumer Commission ("NCC")</List.Item>
          <List.Item>Small Claims Court (Max of R20 000)</List.Item>
          <List.Item>Magistrate  court( Max of R200 000)</List.Item>
          <List.Item>Regional (max of up to R400 000)</List.Item>
        </List>
        <h3>2. The longer version</h3>
        <p>Once a transaction has been frozen by either the sender or recipient of funds:</p>
        1. In the case of no action from the dispute receiver within an hour a notification will be sent to
        the dispute receiver informing them of the dispute and 48 hours to attend to the frozen
        transaction with the user who froze the transaction via the app.
        <List bulleted>
          <List.Item>
            If no action occurs from either party after 24 hours from the initial freeze, a reminder
            will be sent to the seller to resolve, the next two reminders will be sent out after 6 hours
            from each other.
          </List.Item>
          <List.Item>
            48 hours and no action from either party the funds will then be reversed back into the
            senders account or wallet. ( to send via email and maybe contact telephonically on the
            11th hour)
          </List.Item>
        </List>
        2. In the case of multiple freeze transactions that go back and forth.
        <List bulleted>
          <List.Item>
            There will be an allowance for each party to freeze the transaction 3 times each,
            thereafter the issue will require you to approach various consumer complaint bodies
            available to either party or the outcome of an arbitration/trial/ law suit will need to be
            provided.
          </List.Item>
          <List.Item>
            If there is no action between both parties then step one falls into play.
            <Message>
              Once a user freezes a transaction, the receiver will be given the option to either reject or accept the
              freeze.
              <List bulleted>
                <List.Item>
                  In the case where the user accepts the freeze the funds will be  then be reversed into the
                  senders account or foongit wallet within 2 hours
                </List.Item>
                <List.Item>
                  In the case where the user rejects the freeze the following will take place.
                  The rejection notification will be sent back to the user and the funds will remain in
                  the foongit vault until further instruction from the sender is received.
                </List.Item>
              </List>
            </Message>
          </List.Item>
        </List>
        {/* 3. In the case of a freeze transaction which needs the terms and conditions (payment amount, payment date etc) between the parties to be amended.
          <List bulleted>
            <List.Item>
              Once a dispute has been initiated there will be an option to amend the payment date
              and or amount to be paid/received thereafter new authentication will be sent to validate
              the new transaction.
            </List.Item>
          </List> */}
      </p>
    </div>
  )
}, {
  id: 'cost',
  title: 'How do I delete my account',
  body: (
    <div>
      <h5>At foongit, we value your data, and believe you should be able to have full agency over it</h5>
      <p>
        We only store what we need, and we safely do so to ensure your data is safe. If you would like to delete your
        account, we'd be happy to facilitate that, contact us at <a href="mailto:support@foongit.com">support@foongit.com</a>
      </p>
    </div>
  ),
}];

const FAQ = ({ className, location }) => {
  const [activeAccordionIndex, setActiveAccordionIndex] = useState(-1);
  const [deeplink, setDeeplink] = useState<string>(location.hash.substring(1));

  const onAccordionClick = useCallback((_e, titleProps) => {
    setDeeplink('');
    const { index, active } = titleProps;

    setActiveAccordionIndex(active ? -1 : index);
  }, [activeAccordionIndex]);

  return (
    <Layout>
      <SEO title="FAQ" />
      <div className={className}>
        <PageBanner
          title="Frequently Asked Questions"
          subtitle={<span>We love answering questions at foongit. Here’s a couple of frequently asked questions, don’t see yours ? Pop us a mail at <a href="mailto:hello@foongit.com">hello@foongit.com</a> so we can attend to them :).</span>}
        />
        <section className="content">
          <Accordion
            className="registrations"
            fluid
          >
            {rows.map(({ id, title, body }, index) => (
              <>
                <Accordion.Title
                  id={id}
                  key={`title-${index}`}
                  active={activeAccordionIndex === index || id === deeplink}
                  index={index}
                  onClick={onAccordionClick}
                >
                  <span><span>{padNumber(index + 1)}</span></span>
                  <h3>{title}</h3>
                </Accordion.Title>
                <Accordion.Content key={`content-${index}`} active>
                  <Fade
                    collapse
                    top
                    when={activeAccordionIndex === index || id === deeplink}
                  >
                    {body}
                  </Fade>
                </Accordion.Content>
              </>
            ))}
          </Accordion>
        </section>
      </div>
    </Layout>
  );
}

export default styled(FAQ)`
  --accordion-row-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${PageBanner} {
    & a {
      font-weight: 700;
      color: white;
    }
  }

  .content {
    border-radius: 10px;
    padding: 12px;
    overflow: hidden;

    .ui.accordion {
      width: 70vw;
    }

    .ui.accordion .title {
      display: flex;
      background-color: rgba(103, 67, 242, 0.05);
      color: rgba(103,67,242,1);
      height: var(--accordion-row-height);
      align-items: center;

      &.active {
        background-color: rgba(103, 67, 242, 0.1) !important;
      }

      span {
        font-size: 50px;
        font-weight: 700;
        background-color: rgba(103, 67, 242, 0.1) !important;
        display: flex;
        align-items: center;
        justify-content: center;
        height: var(--accordion-row-height);
        width: var(--accordion-row-height);
      }
      h3 {
        font-size: 25px;
        margin-left: 50px;
      }
      @media only screen and (max-width: 768px) {
        span {
          font-size: 45px;
        }
        h3 {
          font-size: 20px;
          margin-left: 25px;
        }
      }
    }

    .cost-breakdown {
      .ui.celled.table tr.uncelled td, .ui.celled.table tr.uncelled th {
        border-left: unset;
      }

      .cost-calculator {
        display: flex;
        flex-direction: column;
      }
    }

    @media only screen and (max-width: 768px) {
      .ui.accordion {
        width: 100%;
      }
    }
  }
`;
